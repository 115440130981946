body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

.bk {
  width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    margin: 0;
    background-image: url(../src/homeBanner.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: block;
    mask-image: linear-gradient(3600deg, rgba(0, 0, 0, 1), transparent 95%)
}


